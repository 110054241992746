import type { NextPage } from 'next';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  Typography,
  Button,
  ThemeProvider,
  Container,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';

import ValidateUserForm from '../components/ValidateUserForm';
import RevalidateUserForm from '../components/RevalidateUserForm';
import RevalidateOptionalUserForm from '../components/RevalidateOptionalUserForm';
import { IdentificationForm } from '../utils/interfaces/identificationForm';
import { identificationSchema } from '../utils/validations/identification-schema';
import { ClientActionEnum, IClient } from 'contactabilidad-common';
import ControlledTextInput from 'ui/components/inputs/controlled-text-input';
import theme from '../styles/theme';
import { searchUser } from '../http/api/users';

const Home: NextPage = () => {
  const [action, setAction] = useState<
    'create' | 'update' | 'update-optional' | 'update-from-optional' | null
  >(null);
  const [identification, setIdentification] = useState<string>('');
  const [dataUser, setDataUser] = useState<IClient | null>(null);
  const [done, setDone] = useState(false);

  const TitleMessage = {
    Create: 'Validar usuario',
    Update: 'Actualizar datos de usuario',
    UpdateOptional: 'Datos vigentes',
  };

  const maxWidthValue = action === 'create' ? '630px' : '100%';

  const {
    control,
    formState: { errors, dirtyFields, isValid },
    reset,
    handleSubmit,
  } = useForm<{ identification: string }>({
    resolver: yupResolver(identificationSchema),
    defaultValues: {
      identification: '',
    },
    mode: 'all',
  });

  const searchUserMutation = useMutation({
    mutationFn: searchUser,
    onSuccess: (data) => {
      setAction(data.action);
      if (data.data !== null) {
        setDataUser(data.data);
      }
      reset();
    },
  });

  const onSearchIdentification = async (data: IdentificationForm) => {
    setIdentification(data.identification);
    await searchUserMutation.mutateAsync(data.identification);
  };

  const selectedTitle = () => {
    return (
      (action === ClientActionEnum.Create && !done && TitleMessage.Create) ||
      ((action === ClientActionEnum.Update ||
        action === 'update-from-optional') &&
        !done &&
        TitleMessage.Update) ||
      ((action === ClientActionEnum.UpdateOptional || done) &&
        TitleMessage.UpdateOptional)
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Grid item xs={12} sx={{ marginTop: theme.spacing(3) }}>
          <Typography
            sx={{
              color: theme.palette.text.primary,
              textAlign: 'center',
            }}
          >
            Realice la búsqueda con la cédula del usuario
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSearchIdentification)}>
          <Grid
            container
            direction="row"
            rowSpacing={1}
            justifyContent="center"
            alignItems="flex-start"
            sx={{
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(7),
            }}
          >
            <Grid item>
              <ControlledTextInput
                label="Cédula"
                name="identification"
                control={control}
                type="text"
                errors={errors.identification}
                isDirty={Boolean(dirtyFields?.identification)}
                inputsProps={{
                  sx: {
                    width: '320px',
                    background: '#fff',
                    marginRight: theme.spacing(1),
                  },
                  maxLength: 10,
                }}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                startIcon={
                  searchUserMutation.isPending && identification ? (
                    <CircularProgress size={25} color="inherit" />
                  ) : (
                    <SearchIcon
                      sx={{
                        width: 25,
                        height: 25,
                      }}
                    />
                  )
                }
                disabled={
                  !isValid ||
                  (searchUserMutation.isPending && Boolean(identification))
                }
                sx={{
                  width: 120,
                  padding: theme.spacing(1.5),
                  height: 55,
                }}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </form>
        {action !== null && (
          <>
            <Grid item xs={12}>
              <Alert
                severity={
                  action === 'update-optional' || done ? 'success' : 'warning'
                }
                style={{
                  justifyContent: 'center',
                  maxWidth: maxWidthValue,
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              >
                {selectedTitle()}
              </Alert>
            </Grid>
            <Divider
              sx={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(5),
                maxWidth: maxWidthValue,
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            />
          </>
        )}
        {action === 'create' && (
          <ValidateUserForm
            identification={identification}
            setAction={setAction}
            done={done}
            setDone={setDone}
          />
        )}
        {(action === 'update' || action === 'update-from-optional') && (
          <RevalidateUserForm
            identification={identification}
            setAction={setAction}
            actionValue={action}
            dataUser={dataUser}
            done={done}
            setDone={setDone}
          />
        )}
        {action === 'update-optional' && (
          <RevalidateOptionalUserForm
            identification={identification}
            setAction={setAction}
            dataUser={dataUser}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Home;
