import { Dispatch, SetStateAction, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { Control, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { Button, Box, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useMutation } from '@tanstack/react-query';

import { ValidateForm } from '../utils/interfaces/validateForm';
import ControlledTextInput from 'ui/components/inputs/controlled-text-input';
import ControlledDesktopDatePickerInput from 'ui/components/inputs/controlled-desktop-date-picker-input';
import theme from '../styles/theme';
import { validateId } from '../http/api/users';

interface IValidateUserIdentificationFormProps {
  identification: string;
  setDisabledContactValues: Dispatch<SetStateAction<boolean>>;
  disabledContactValues: boolean;
  setValue: UseFormSetValue<ValidateForm>;
  expeditionDate: Date | null;
  control: Control<ValidateForm>;
  errors: FieldErrors<ValidateForm>;
  dirtyFields: Partial<
    Readonly<{
      name?: boolean;
      lastName?: boolean;
      expeditionDate?: boolean;
      phone?: boolean;
      otp?: boolean;
      hasContract?: boolean;
      contract?: boolean;
      contractRelationship?: boolean;
    }>
  >;
}

const ValidateUserIdentificationForm = (
  props: IValidateUserIdentificationFormProps
) => {
  const {
    identification,
    setDisabledContactValues,
    disabledContactValues,
    setValue,
    expeditionDate,
    control,
    errors,
    dirtyFields,
  } = props;

  const validateIdMutation = useMutation({
    mutationFn: validateId,
    onSuccess: (data) => {
      if (data.data.identificationValidationId !== null) {
        setValue('name', data.data.clientName);
        setValue('lastName', data.data.clientLastName);
        setDisabledContactValues(false);
        setValue('editContract', true);
      }
    },
  });

  const onValidateIdentification = useCallback(async () => {
    if (!expeditionDate || errors.expeditionDate) {
      return;
    }

    validateIdMutation.mutateAsync({
      identification,
      expeditionDate: expeditionDate.toISOString(),
    });
  }, [
    expeditionDate,
    identification,
    validateIdMutation,
    errors.expeditionDate,
  ]);

  return (
    <Grid
      container
      direction="row"
      rowSpacing={2}
      columnSpacing={4}
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid item>
        <TextField
          id="identification"
          label="Cédula"
          value={identification}
          InputProps={{
            disabled: true,
          }}
          sx={{
            width: '300px',
          }}
        />
      </Grid>
      <Grid item>
        <Box display="flex" justifyContent="flex-end">
          <ControlledDesktopDatePickerInput
            label="Fecha expedición"
            name="expeditionDate"
            control={control}
            onEnterKeyPress={onValidateIdentification}
            inputWidth="192px"
            displayFormat="dd/MM/yyyy"
            inputsProps={{
              disabled: !disabledContactValues,
            }}
            errors={errors.expeditionDate}
            isDirty={Boolean(dirtyFields?.expeditionDate)}
            autofocus={true}
            resetKey={identification}
          />
          <Button
            variant="outlined"
            disabled={
              !expeditionDate ||
              Boolean(errors.expeditionDate) ||
              !disabledContactValues ||
              validateIdMutation.isPending
            }
            onClick={onValidateIdentification}
            sx={{
              height: 55,
              width: 100,
              marginLeft: theme.spacing(1),
              fontSize: 'small',
              textTransform: 'none',
              padding: theme.spacing(1.5),
              fontWeight: '600',
            }}
          >
            {validateIdMutation.isPending ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              'Validar'
            )}
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <ControlledTextInput
          label="Nombres"
          name="name"
          control={control}
          type="text"
          inputsProps={{
            sx: {
              width: '300px',
            },
            disabled: true,
          }}
          errors={errors.name}
          isDirty={Boolean(dirtyFields?.name)}
        />
      </Grid>
      <Grid item>
        <ControlledTextInput
          label="Apellidos"
          name="lastName"
          control={control}
          type="text"
          inputsProps={{
            sx: {
              width: '300px',
            },
            disabled: true,
          }}
          errors={errors.lastName}
          isDirty={Boolean(dirtyFields?.lastName)}
        />
      </Grid>
    </Grid>
  );
};

export default ValidateUserIdentificationForm;
