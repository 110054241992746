import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { IClient } from 'contactabilidad-common';

interface IUserIdentificationFormProps {
  identification: string;
  dataUser: IClient | null;
}

const UserIdentificationForm = (props: IUserIdentificationFormProps) => {
  const { identification, dataUser } = props;

  return (
    <>
      <Grid item xs={12} sx={{ marginBottom: '20px' }}>
        <Typography color="textPrimary" sx={{ fontWeight: '600' }}>
          Datos personales:
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        rowSpacing={2}
        columnSpacing={4}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <TextField
            id="identification"
            label="Cédula"
            value={identification}
            InputProps={{
              disabled: true,
            }}
            sx={{
              width: '260px',
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="names"
            label="Nombres"
            value={dataUser?.name}
            InputProps={{
              disabled: true,
            }}
            sx={{
              width: '260px',
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="lastNames"
            label="Apellidos"
            value={dataUser?.lastName}
            InputProps={{
              disabled: true,
            }}
            sx={{
              width: '260px',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserIdentificationForm;
