import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Grid from '@mui/material/Grid';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import { Button, Box, CircularProgress } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';

import ControlledTextInput from 'ui/components/inputs/controlled-text-input';
import theme from '../styles/theme';
import { CreatePhoneValidationDto } from 'contactabilidad-common';
import { sendCode, validateUserPhone } from '../http/api/users';

type OptionalInterface<T> = {
  [key in keyof T]?: boolean | boolean[] | undefined;
};

interface IValidateUserPhoneFormProps<T extends FieldValues> {
  action: 'create' | 'update';
  disabledContactValues?: boolean;
  retries: number;
  setRetries: Dispatch<SetStateAction<number>>;
  phoneValidationId: string;
  setPhoneValidationId: Dispatch<SetStateAction<string>>;
  validatePhone: boolean;
  setValidatePhone: Dispatch<SetStateAction<boolean>>;
  phone: string;
  otp: string;
  control: Control<T>;
  errors: FieldErrors<T>;
  dirtyFields: Partial<Readonly<OptionalInterface<T>>>;
}

const RESEND_FLAG_SECONDS_DELAY = 30;

const ValidateUserPhoneForm = <T extends FieldValues>(
  props: IValidateUserPhoneFormProps<T>
): JSX.Element => {
  const {
    action,
    disabledContactValues,
    retries,
    setRetries,
    phoneValidationId,
    setPhoneValidationId,
    validatePhone,
    setValidatePhone,
    phone,
    otp,
    control,
    errors,
    dirtyFields,
  } = props;

  const [counter, setCounter] = useState(RESEND_FLAG_SECONDS_DELAY);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [canResend, setCanResend] = useState(true);
  const [phoneValidationIdOtp, setPhoneValidationIdOtp] = useState('');

  const resetResendFlag = useCallback(() => {
    const countdown = (c: number) => c - 1;
    const interval = setInterval(() => {
      setCounter(countdown);
    }, 1000);
    setTimer(interval);
  }, []);

  useEffect(() => {
    return () => clearInterval(timer);
  }, [timer]);

  useEffect(() => {
    if (counter <= 0) {
      clearInterval(timer);
      setTimer(undefined);
      setCanResend(true);
    }
  }, [counter, timer]);

  const sendCodeMutation = useMutation({
    mutationFn: sendCode,
  });

  const onSendCode = useCallback(async () => {
    if (!canResend || retries >= 5 || !phone || errors.phone) {
      return;
    }

    setCounter(RESEND_FLAG_SECONDS_DELAY);
    const phoneCountryCode = '57';
    const parameters: CreatePhoneValidationDto = {
      phone,
      phoneCountryCode,
    };
    if (retries > 0) {
      parameters.parentValidationId = phoneValidationIdOtp;
    }

    const response = await sendCodeMutation.mutateAsync(parameters);

    setPhoneValidationId(response.phoneValidationId);
    if (retries === 0) {
      setPhoneValidationIdOtp(response.phoneValidationId);
    }

    resetResendFlag();
    setCanResend(false);
    setRetries((prev) => prev + 1);
  }, [
    canResend,
    retries,
    phone,
    errors.phone,
    sendCodeMutation,
    setPhoneValidationId,
    resetResendFlag,
    setRetries,
    phoneValidationIdOtp,
  ]);

  const handleOnEnterKeyPressPhone = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      onSendCode();
    }
  };

  const onEditPhone = () => {
    resetResendFlag();
    setCanResend(true);
    setRetries(0);
  };

  const validatePhoneMutation = useMutation({
    mutationFn: validateUserPhone,
  });

  const onValidatePhone = useCallback(async () => {
    if (otp && !errors.otp && phone && !errors.phone) {
      const phoneCountryCode = '57';
      const phoneValidationCode = parseInt(otp, 10);
      const phoneData = {
        phone,
        phoneCountryCode,
        phoneValidationId,
        phoneValidationCode,
      };

      const response = await validatePhoneMutation.mutateAsync(phoneData);

      if (response.isPhoneCodeValid) {
        setValidatePhone(true);
        toast.success('¡Código validado!');
      } else {
        toast.error('¡Código incorrecto!');
      }
    }
  }, [
    otp,
    errors.otp,
    errors.phone,
    phone,
    phoneValidationId,
    validatePhoneMutation,
    setValidatePhone,
  ]);

  const handleOnEnterKeyPressOTP = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      onValidatePhone();
    }
  };

  const sendCodeButtonContent = () => {
    if (sendCodeMutation.isPending) {
      return <CircularProgress size={25} color="inherit" />;
    }
    if (validatePhone) {
      return 'Reenviar código';
    }
    if (!canResend && counter >= 10) {
      return `00:${counter}`;
    }
    if (!canResend && counter < 10) {
      return `00:0${counter}`;
    }
    if (canResend && retries > 0 && retries < 5) {
      return 'Reenviar código';
    }
    if (retries === 5) {
      return 'Envíos superados';
    }
    return 'Enviar código';
  };

  const validateOtpButtonContent = () => {
    if (validatePhoneMutation.isPending) {
      return <CircularProgress size={25} color="inherit" />;
    }
    return 'Validar';
  };

  const disableValidateOtpButton = () => {
    return (
      (action === 'create' && disabledContactValues) ||
      validatePhone ||
      !otp ||
      Boolean(errors.otp) ||
      !phone ||
      Boolean(errors.phone) ||
      validatePhoneMutation.isPending
    );
  };

  const disableSendCodeButton = () => {
    return (
      (action === 'create' && disabledContactValues) ||
      validatePhone ||
      !phone ||
      Boolean(errors.phone) ||
      retries >= 5 ||
      !canResend ||
      sendCodeMutation.isPending
    );
  };

  const getPhoneInputWidth = () => {
    if (!canResend && !validatePhone) {
      return '137px';
    }
    if (action === 'create') {
      return '152px';
    }
    return '192px';
  };

  return (
    <Grid
      container
      direction="row"
      rowSpacing={2}
      columnSpacing={4}
      justifyContent={action === 'create' ? 'center' : 'flex-start'}
      alignItems="flex-start"
      marginTop={theme.spacing(3)}
    >
      <Grid item>
        <Box display="flex" justifyContent="flex-end">
          <ControlledTextInput
            label="Celular"
            name="phone"
            control={control}
            type="text"
            inputsProps={{
              sx: {
                width: getPhoneInputWidth(),
              },
              disabled: disabledContactValues || validatePhone || !canResend,
              maxLength: 10,
              onKeyDown: handleOnEnterKeyPressPhone,
            }}
            errors={errors.phone}
            isDirty={Boolean(dirtyFields?.phone)}
          />
          {!canResend && !validatePhone && (
            <Button
              variant="outlined"
              onClick={onEditPhone}
              sx={{
                height: 55,
                width: 45,
                minWidth: 45,
                marginLeft: theme.spacing(1),
                padding: '0px',
                '&:hover': {
                  '.icono': {
                    color: '#fff',
                  },
                },
              }}
            >
              <EditIcon
                className="icono"
                sx={{
                  width: 25,
                  height: 25,
                  color: '#1050b3',
                }}
              />
            </Button>
          )}
          <Button
            variant="outlined"
            disabled={disableSendCodeButton()}
            onClick={onSendCode}
            sx={{
              height: 55,
              width:
                action !== 'create' && !canResend && !validatePhone ? 63 : 100,
              minWidth: 60,
              marginLeft: theme.spacing(1),
              fontSize: 'small',
              textTransform: 'none',
              padding: theme.spacing(1.5),
              fontWeight: '600',
            }}
          >
            {sendCodeButtonContent()}
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Box display="flex" justifyContent="flex-start">
          <ControlledTextInput
            label="OTP"
            name="otp"
            control={control}
            type="text"
            inputsProps={{
              sx: {
                width: action === 'create' ? '192px' : '152px',
              },
              disabled: disabledContactValues || validatePhone,
              maxLength: 6,
              onKeyDown: handleOnEnterKeyPressOTP,
            }}
            errors={errors.otp}
            isDirty={Boolean(dirtyFields?.otp)}
          />
          <Button
            variant="outlined"
            disabled={disableValidateOtpButton()}
            onClick={onValidatePhone}
            sx={{
              height: 55,
              width: 100,
              marginLeft: theme.spacing(1),
              fontSize: 'small',
              textTransform: 'none',
              padding: theme.spacing(1.5),
              fontWeight: '600',
            }}
          >
            {validateOtpButtonContent()}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ValidateUserPhoneForm;
