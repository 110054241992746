import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import UserIdentificationForm from './UserIdentificationForm';
import ValidateUserContractForm from './ValidateUserContractForm';
import { RevalidateOptionalForm } from '../utils/interfaces/revalidateOptionalForm';
import { revalidateOptionalSchema } from '../utils/validations/revalidateOptional-schema';
import theme from '../styles/theme';
import {
  ClientActionEnum,
  IClient,
  UpdateClientDto,
} from 'contactabilidad-common';
import { revalidateUser } from '../http/api/users';
import UserCommunicationChannelForm from './UserCommunicationChannelForm';
import {
  VERBAL_COMMUNICATION_CHANNEL_LIST,
  WRITTEN_COMMUNICATION_CHANNEL_LIST,
} from '../utils/constants';

interface IRevalidateOptionalUserFormProps {
  identification: string;
  setAction: Dispatch<
    SetStateAction<
      'create' | 'update' | 'update-optional' | 'update-from-optional' | null
    >
  >;
  dataUser: IClient | null;
}

const RevalidateOptionalUserForm = (
  props: IRevalidateOptionalUserFormProps
) => {
  const { identification, setAction, dataUser } = props;

  const [contractAddress, setContractAddress] = useState('');
  const [validateContract, setValidateContract] = useState(false);

  const defaultValues = useMemo(
    (): RevalidateOptionalForm => ({
      editContract: false,
      contract: '',
      writtenCommunicationChannels: WRITTEN_COMMUNICATION_CHANNEL_LIST.map(
        (channel) => ({
          channel,
          included: Boolean(dataUser?.communicationChannels.includes(channel)),
        })
      ),
      verbalCommunicationChannels: VERBAL_COMMUNICATION_CHANNEL_LIST.map(
        (channel) => ({
          channel,
          included: Boolean(dataUser?.communicationChannels.includes(channel)),
        })
      ),
    }),
    [dataUser?.communicationChannels]
  );

  const {
    setValue,
    control,
    watch,
    formState: { errors, dirtyFields, isValid },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm<RevalidateOptionalForm>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(revalidateOptionalSchema),
  });

  useEffect(() => {
    setContractAddress('');
    setValidateContract(false);
    reset(defaultValues);
  }, [defaultValues, identification, reset]);

  const onValidate = () => {
    setAction('update-from-optional');
  };

  const revalidateUserMutation = useMutation({
    mutationFn: revalidateUser,
    onSuccess: (data) => {
      if (data.action === 'updated') {
        setAction(null);
        toast.success('¡Datos de contrato actualizados!');
      } else {
        toast.error('¡Operación fallida!');
      }
    },
  });

  const onAccept = async (data: RevalidateOptionalForm) => {
    const {
      contractRelationship,
      writtenCommunicationChannels,
      verbalCommunicationChannels,
    } = data;

    const communicationChannels = [
      ...writtenCommunicationChannels,
      ...verbalCommunicationChannels,
    ]
      .filter((el) => el.included)
      .map((el) => el.channel);

    if (communicationChannels.length === 0) {
      toast.error('¡Debe seleccionar al menos un medio de comunicación!');
      return;
    }

    const hasRemovedCommunicationChannels =
      dataUser?.communicationChannels.some(
        (channel) => !communicationChannels.includes(channel)
      );

    const hasAddedCommunicationChannels = communicationChannels.some(
      (channel) => !dataUser?.communicationChannels.includes(channel)
    );

    if (
      !watch('editContract') &&
      !hasRemovedCommunicationChannels &&
      !hasAddedCommunicationChannels
    ) {
      setAction(null);
      return;
    }

    const action = ClientActionEnum.UpdateOptional;
    const contractId = parseInt(data.contract!, 10);

    const userData: UpdateClientDto = {
      action,
      contractId,
      contractRelationship,
      communicationChannels,
    };

    await revalidateUserMutation.mutateAsync({ userData, identification });
  };

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit(onAccept)}>
        <UserIdentificationForm
          identification={identification}
          dataUser={dataUser}
        />
        <Grid
          item
          xs={12}
          sx={{ marginTop: theme.spacing(5), marginBottom: theme.spacing(2.5) }}
        >
          <Typography color="textPrimary" sx={{ fontWeight: '600' }}>
            Información de contacto:
          </Typography>
        </Grid>
        <Grid container direction="row" rowSpacing={2} columnSpacing={4}>
          <Grid item>
            <TextField
              id="phone"
              label="Celular"
              value={dataUser?.phone}
              InputProps={{
                disabled: true,
              }}
              sx={{
                width: '260px',
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: theme.spacing(5) }}>
          <Typography color="textPrimary" sx={{ fontWeight: '600' }}>
            Información de contratos relacionados:
          </Typography>
        </Grid>
        {dataUser?.contractId !== null ? (
          <Grid
            container
            direction="row"
            rowSpacing={2}
            columnSpacing={4}
            sx={{ marginTop: theme.spacing(2.5) }}
          >
            <Grid item>
              <TextField
                id="contract"
                label="Número de contrato"
                value={dataUser?.contractId}
                InputProps={{
                  disabled: true,
                }}
                sx={{
                  width: '260px',
                }}
              />
            </Grid>
            <Grid item>
              <Box display="flex">
                <TextField
                  id="propertyLink"
                  label="Relación con predio"
                  value={dataUser?.contractRelationship}
                  InputProps={{
                    disabled: true,
                  }}
                  sx={{
                    width: '260px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <ValidateUserContractForm<RevalidateOptionalForm>
            action={'update-optional'}
            setValue={setValue}
            clearErrors={clearErrors}
            editContractEnabled={watch('editContract')}
            contract={watch('contract')}
            contractAddress={contractAddress}
            setContractAddress={setContractAddress}
            validateContract={validateContract}
            setValidateContract={setValidateContract}
            control={control}
            errors={errors}
            dirtyFields={dirtyFields as any}
            defaultValues={defaultValues}
          />
        )}
        <UserCommunicationChannelForm
          control={control as any}
          errors={errors}
          dirtyFields={dirtyFields as any}
          fullWidthTitle
        />
        <Box display="flex" justifyContent="flex-start" marginTop="50px">
          <Button variant="outlined" onClick={onValidate} sx={{ width: 110 }}>
            Validar
          </Button>
          <Button
            variant="contained"
            disabled={!isValid || (watch('editContract') && !validateContract)}
            sx={{
              marginLeft: 2,
              width: 110,
            }}
            onClick={handleSubmit(onAccept)}
          >
            {revalidateUserMutation.isPending ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              'Aceptar'
            )}
          </Button>
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default RevalidateOptionalUserForm;
