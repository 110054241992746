import api from '../api';

import {
  CreateClientDto,
  ValidateUserResponseDto,
  ValidatePhoneDto,
  ValidatePhoneResponseDto,
  CreatePhoneValidationDto,
  SendCodeResponseDto,
  ValidateIdentificationDto,
  ValidateIdResponseDto,
  SearchIdResponseDto,
  UpdateClientDto,
  ValidateContractResponseDto,
} from 'contactabilidad-common';

export const validateUser = async (userData: CreateClientDto) => {
  const res = await api.post<ValidateUserResponseDto>('/clients', userData);
  return res.data;
};

export const sendCode = async (sendData: CreatePhoneValidationDto) => {
  const res = await api.post<SendCodeResponseDto>(
    '/phone-validation',
    sendData
  );

  return res.data;
};

export const validateUserPhone = async (phoneData: ValidatePhoneDto) => {
  const res = await api.post<ValidatePhoneResponseDto>(
    '/phone-validation/validate',
    phoneData
  );

  return res.data;
};

export const validateId = async (
  identificationData: ValidateIdentificationDto
) => {
  const res = await api.post<ValidateIdResponseDto>(
    '/clients/validate-identification',
    identificationData
  );

  return res.data;
};

export const searchUser = async (identification: string) => {
  const res = await api.get<SearchIdResponseDto>(`/clients/${identification}`);
  return res.data;
};

export const revalidateUser = async ({
  userData,
  identification,
}: {
  userData: UpdateClientDto;
  identification: string;
}) => {
  const res = await api.put<ValidateUserResponseDto>(
    `/clients/${identification}`,
    userData
  );

  return res.data;
};

export const validateUserContract = async (contractId: number) => {
  const res = await api.get<ValidateContractResponseDto>(
    `/contracts/${contractId}`
  );

  return res.data;
};
