import { Divider, Grid, Typography } from '@mui/material';
import { CommunicationChannelEnum } from 'contactabilidad-common';
import { Control, FieldErrors, useFieldArray } from 'react-hook-form';
import { CommunicationChannelData } from '../utils/interfaces/communicationChannelData.interface';
import theme from '../styles/theme';
import ControlledCheckbox from 'ui/components/inputs/controlled-checkbox-input';

interface FormValues {
  writtenCommunicationChannels: CommunicationChannelData[];
  verbalCommunicationChannels: CommunicationChannelData[];
  [x: string | number | symbol]: any;
}

interface IUserCommunicationChannelForm {
  control: Control<FormValues>;
  errors: FieldErrors<FormValues>;
  dirtyFields: Partial<FormValues>;
  done?: boolean;
  fullWidthTitle?: boolean;
}

const COMMUNICATION_CHANNEL_TEXT = {
  [CommunicationChannelEnum.Letter]: 'Carta',
  [CommunicationChannelEnum.Email]: 'Correo Electrónico',
  [CommunicationChannelEnum.SMS]: 'Mensaje de texto (SMS)',
  [CommunicationChannelEnum.WhatsApp]: 'WhatsApp',
  [CommunicationChannelEnum.PhoneCall]: 'Llamada',
  [CommunicationChannelEnum.TtsBot]: 'Bot',
  [CommunicationChannelEnum.OnSiteVisit]: 'Visita al lugar de servicio',
};

function UserCommunicationChannelForm({
  control,
  errors,
  dirtyFields,
  done,
  fullWidthTitle = false,
}: IUserCommunicationChannelForm) {
  const { fields: writtenCommunicationChannelsFields } = useFieldArray({
    control,
    name: 'writtenCommunicationChannels',
  });

  const { fields: verbalCommunicationChannelsFields } = useFieldArray({
    control,
    name: 'verbalCommunicationChannels',
  });

  const renderCommunicationChannels = ({
    fields,
    name,
    label,
  }: Record<string, any>) => (
    <Grid item alignSelf="flex-start" xs={4}>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Typography color="textPrimary">{label}</Typography>
        </Grid>
        {fields.map(
          (field: CommunicationChannelData & { id: string }, i: number) => (
            <Grid
              key={field.id}
              item
              xs={12}
              sx={{ marginLeft: theme.spacing(3) }}
            >
              <ControlledCheckbox
                label={
                  <Typography color="textPrimary">
                    {COMMUNICATION_CHANNEL_TEXT[field.channel]}
                  </Typography>
                }
                control={control}
                name={`${name}.${i}.included`}
                errors={(errors?.[name] as any)?.[i]}
                isDirty={Boolean(dirtyFields?.[name]?.[i]?.included)}
                checkboxProps={{ disabled: done }}
              />
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent={'center'}
      alignItems="center"
      sx={{ marginTop: theme.spacing(1) }}
    >
      <Grid
        item
        xs={fullWidthTitle ? 12 : 9}
        sx={{ marginBottom: theme.spacing(3) }}
      >
        <Typography color="textPrimary" sx={{ fontWeight: '600' }}>
          Canales de contacto:
        </Typography>
      </Grid>
      {renderCommunicationChannels({
        fields: writtenCommunicationChannelsFields,
        name: 'writtenCommunicationChannels',
        label: 'Medios de contacto escrito:',
      })}
      <Grid item alignSelf="stretch" sx={{ marginRight: theme.spacing(6) }}>
        <Divider orientation="vertical" />
      </Grid>
      {renderCommunicationChannels({
        fields: verbalCommunicationChannelsFields,
        name: 'verbalCommunicationChannels',
        label: 'Medios de contacto verbal:',
      })}
    </Grid>
  );
}

export default UserCommunicationChannelForm;
