import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Button, Box, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeProvider } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import ValidateUserIdentificationForm from './ValidateUserIdentificationForm';
import ValidateUserPhoneForm from './ValidateUserPhoneForm';
import ValidateUserContractForm from './ValidateUserContractForm';
import { ValidateForm } from '../utils/interfaces/validateForm';
import { validateSchema } from '../utils/validations/validate-schema';
import theme from '../styles/theme';
import {
  CommunicationChannelEnum,
  CreateClientDto,
} from 'contactabilidad-common';
import { validateUser } from '../http/api/users';
import UserCommunicationChannelForm from './UserCommunicationChannelForm';

interface IValidateUserFormProps {
  identification: string;
  setAction: Dispatch<
    SetStateAction<
      'create' | 'update' | 'update-optional' | 'update-from-optional' | null
    >
  >;
  done: boolean;
  setDone: Dispatch<SetStateAction<boolean>>;
}

const getDefaultValues = (): Partial<ValidateForm> => ({
  name: '',
  lastName: '',
  phone: '',
  otp: '',
  contract: '',
  editContract: false,
  writtenCommunicationChannels: [
    { channel: CommunicationChannelEnum.Letter, included: true },
    { channel: CommunicationChannelEnum.Email, included: true },
    { channel: CommunicationChannelEnum.SMS, included: true },
    { channel: CommunicationChannelEnum.WhatsApp, included: true },
  ],
  verbalCommunicationChannels: [
    { channel: CommunicationChannelEnum.PhoneCall, included: true },
    { channel: CommunicationChannelEnum.TtsBot, included: true },
    { channel: CommunicationChannelEnum.OnSiteVisit, included: true },
  ],
});

const ValidateUserForm = (props: IValidateUserFormProps) => {
  const { identification, setAction, done, setDone } = props;

  const [disabledContactValues, setDisabledContactValues] = useState(true);
  const [validatePhone, setValidatePhone] = useState(false);
  const [retries, setRetries] = useState(0);
  const [phoneValidationId, setPhoneValidationId] = useState<string>('');
  const [contractAddress, setContractAddress] = useState('');
  const [validateContract, setValidateContract] = useState(false);

  const {
    setValue,
    control,
    watch,
    formState: { errors, dirtyFields, isValid },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm<ValidateForm>({
    defaultValues: getDefaultValues(),
    mode: 'all',
    resolver: yupResolver(validateSchema),
  });

  useEffect(() => {
    setDisabledContactValues(true);
    setValidatePhone(false);
    setRetries(0);
    setPhoneValidationId('');
    setContractAddress('');
    setValidateContract(false);
    setDone(false);
    reset(getDefaultValues());
  }, [identification, reset, setDone]);

  const validateUserMutation = useMutation({
    mutationFn: validateUser,
    onSuccess: (data) => {
      if (data.action === 'validated') {
        setDone(true);
        toast.success('¡Usuario validado!');
      } else {
        toast.error('¡Operación fallida!');
      }
    },
  });

  const onConfirmValidate = async (data: ValidateForm) => {
    const { writtenCommunicationChannels, verbalCommunicationChannels } = data;
    const communicationChannels = [
      ...writtenCommunicationChannels,
      ...verbalCommunicationChannels,
    ]
      .filter((el) => el.included)
      .map((el) => el.channel);

    if (communicationChannels.length === 0) {
      toast.error('¡Debe seleccionar al menos un canal!');
      return;
    }

    const phone = data.phone;
    const phoneCountryCode = '57';

    const parameters: CreateClientDto = {
      identification,
      phone,
      phoneCountryCode,
      phoneValidationId,
      communicationChannels,
    };

    if (data.contract) {
      parameters.contractId = parseInt(data.contract, 10);
      parameters.contractRelationship = data.contractRelationship;
    }

    validateUserMutation.mutateAsync(parameters);
  };

  const onCancelValidate = () => {
    setAction(null);
  };

  const SubmitButtonContent = () => {
    return validateUserMutation.isPending ? (
      <CircularProgress size={25} color="inherit" />
    ) : done === true ? (
      'Aceptar'
    ) : (
      'Finalizar'
    );
  };

  const expeditionDate = watch('expeditionDate');

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit(onConfirmValidate)}>
        <ValidateUserIdentificationForm
          identification={identification}
          setDisabledContactValues={setDisabledContactValues}
          disabledContactValues={disabledContactValues}
          setValue={setValue}
          expeditionDate={expeditionDate}
          control={control}
          errors={errors}
          dirtyFields={dirtyFields}
        />
        <ValidateUserPhoneForm
          action={'create'}
          disabledContactValues={disabledContactValues}
          retries={retries}
          setRetries={setRetries}
          phoneValidationId={phoneValidationId}
          setPhoneValidationId={setPhoneValidationId}
          validatePhone={validatePhone}
          setValidatePhone={setValidatePhone}
          phone={watch('phone')}
          otp={watch('otp')}
          control={control}
          errors={errors}
          dirtyFields={dirtyFields as any}
        />
        <ValidateUserContractForm<ValidateForm>
          action={'create'}
          disabledContactValues={disabledContactValues}
          setValue={setValue}
          clearErrors={clearErrors}
          editContractEnabled={watch('editContract')}
          contract={watch('contract')}
          contractAddress={contractAddress}
          setContractAddress={setContractAddress}
          validateContract={validateContract}
          setValidateContract={setValidateContract}
          control={control}
          errors={errors}
          dirtyFields={dirtyFields as any}
          defaultValues={getDefaultValues()}
          done={done}
        />
        <UserCommunicationChannelForm
          control={control as any}
          errors={errors as any}
          dirtyFields={dirtyFields as any}
          done={done}
        />
        <Box
          display="flex"
          marginLeft="auto"
          marginRight="auto"
          justifyContent="flex-end"
          marginTop={theme.spacing(6.5)}
          sx={{ maxWidth: '630px' }}
        >
          {!done && (
            <Button
              variant="outlined"
              onClick={onCancelValidate}
              sx={{ width: 110 }}
            >
              Cancelar
            </Button>
          )}
          <Button
            variant="contained"
            onClick={done ? onCancelValidate : handleSubmit(onConfirmValidate)}
            disabled={
              !isValid ||
              !validatePhone ||
              (watch('editContract') && !validateContract)
            }
            sx={{
              marginLeft: theme.spacing(1),
              width: 110,
            }}
          >
            {SubmitButtonContent()}
          </Button>
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default ValidateUserForm;
