import { useState, Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeProvider } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import UserIdentificationForm from './UserIdentificationForm';
import ValidateUserPhoneForm from './ValidateUserPhoneForm';
import ValidateUserContractForm from './ValidateUserContractForm';
import { RevalidateForm } from '../utils/interfaces/revalidateForm';
import { revalidateSchema } from '../utils/validations/revalidate-schema';
import theme from '../styles/theme';
import {
  ClientActionEnum,
  IClient,
  UpdateClientDto,
} from 'contactabilidad-common';
import { revalidateUser } from '../http/api/users';
import UserCommunicationChannelForm from './UserCommunicationChannelForm';
import {
  VERBAL_COMMUNICATION_CHANNEL_LIST,
  WRITTEN_COMMUNICATION_CHANNEL_LIST,
} from '../utils/constants';

interface IRevalidateUserFormProps {
  identification: string;
  setAction: Dispatch<
    SetStateAction<
      'create' | 'update' | 'update-optional' | 'update-from-optional' | null
    >
  >;
  actionValue: string;
  dataUser: IClient | null;
  done: boolean;
  setDone: Dispatch<SetStateAction<boolean>>;
}

const RevalidateUserForm = (props: IRevalidateUserFormProps) => {
  const { identification, setAction, actionValue, dataUser, done, setDone } =
    props;
  const [validatePhone, setValidatePhone] = useState(false);
  const [retries, setRetries] = useState(0);
  const [phoneValidationId, setPhoneValidationId] = useState<string>('');
  const [contractAddress, setContractAddress] = useState('');
  const [validateContract, setValidateContract] = useState(false);

  const defaultValues = useMemo(
    (): RevalidateForm => ({
      phone: '',
      otp: '',
      editContract: dataUser?.contractId === null,
      contract: dataUser?.contractId?.toString() ?? '',
      contractRelationship: dataUser?.contractRelationship,
      writtenCommunicationChannels: WRITTEN_COMMUNICATION_CHANNEL_LIST.map(
        (channel) => ({
          channel,
          included: Boolean(dataUser?.communicationChannels.includes(channel)),
        })
      ),
      verbalCommunicationChannels: VERBAL_COMMUNICATION_CHANNEL_LIST.map(
        (channel) => ({
          channel,
          included: Boolean(dataUser?.communicationChannels.includes(channel)),
        })
      ),
    }),
    [dataUser]
  );

  const {
    setValue,
    control,
    watch,
    formState: { errors, dirtyFields, isValid },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm<RevalidateForm>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(revalidateSchema),
  });

  useEffect(() => {
    setValidatePhone(false);
    setRetries(0);
    setPhoneValidationId('');
    setContractAddress('');
    setValidateContract(false);
    setDone(false);
    reset(defaultValues);
  }, [identification, reset, defaultValues, setDone]);

  const revalidateUserMutation = useMutation({
    mutationFn: revalidateUser,
    onSuccess: (data) => {
      if (data.action === 'updated') {
        setDone(true);
        toast.success('¡Usuario validado!');
      } else {
        toast.error('¡Operación fallida!');
      }
    },
  });

  const onConfirmValidate = async (data: RevalidateForm) => {
    const action = ClientActionEnum.Update;
    const {
      phone,
      contract,
      contractRelationship,
      writtenCommunicationChannels,
      verbalCommunicationChannels,
    } = data;

    const communicationChannels = [
      ...writtenCommunicationChannels,
      ...verbalCommunicationChannels,
    ]
      .filter((el) => el.included)
      .map((el) => el.channel);

    if (communicationChannels.length === 0) {
      toast.error('¡Debe seleccionar al menos un medio de comunicación!');
      return;
    }

    const phoneCountryCode = '57';

    const userData: UpdateClientDto = {
      action,
      phone,
      phoneCountryCode,
      phoneValidationId,
      communicationChannels,
    };

    if (contract) {
      userData.contractId = parseInt(contract, 10);
      userData.contractRelationship = contractRelationship;
    }

    await revalidateUserMutation.mutateAsync({ userData, identification });
  };

  const onCancelValidate = () => {
    if (actionValue === 'update-from-optional') {
      setAction('update-optional');
    } else {
      setAction(null);
    }
  };

  const onAccept = () => {
    setAction(null);
  };

  const SubmitButtonContent = () => {
    return revalidateUserMutation.isPending ? (
      <CircularProgress size={25} color="inherit" />
    ) : done === true ? (
      'Aceptar'
    ) : (
      'Finalizar'
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit(onConfirmValidate)}>
        <UserIdentificationForm
          identification={identification}
          dataUser={dataUser}
        />
        <Grid
          item
          xs={12}
          sx={{ marginTop: theme.spacing(5), marginBottom: theme.spacing(2.5) }}
        >
          <Typography color="textPrimary" sx={{ fontWeight: '600' }}>
            Información de contacto:
          </Typography>
        </Grid>
        <ValidateUserPhoneForm<RevalidateForm>
          action={'update'}
          retries={retries}
          setRetries={setRetries}
          phoneValidationId={phoneValidationId}
          setPhoneValidationId={setPhoneValidationId}
          validatePhone={validatePhone}
          setValidatePhone={setValidatePhone}
          phone={watch('phone')}
          otp={watch('otp')}
          control={control}
          errors={errors}
          dirtyFields={dirtyFields as any}
        />
        <Grid item xs={12} sx={{ marginTop: theme.spacing(5) }}>
          <Typography color="textPrimary" sx={{ fontWeight: '600' }}>
            Información de contratos relacionados:
          </Typography>
        </Grid>
        <ValidateUserContractForm<RevalidateForm>
          action={'update'}
          setValue={setValue}
          clearErrors={clearErrors}
          editContractEnabled={watch('editContract')}
          contract={watch('contract')}
          contractAddress={contractAddress}
          setContractAddress={setContractAddress}
          validateContract={validateContract}
          setValidateContract={setValidateContract}
          control={control}
          errors={errors}
          dirtyFields={dirtyFields as any}
          defaultValues={defaultValues}
          done={done}
        />
        <UserCommunicationChannelForm
          control={control as any}
          errors={errors}
          dirtyFields={dirtyFields as any}
          done={done}
          fullWidthTitle
        />
        <Box display="flex" justifyContent="flex-start" marginTop="50px">
          {!done && (
            <Button
              variant="outlined"
              onClick={onCancelValidate}
              sx={{ width: 110 }}
            >
              Cancelar
            </Button>
          )}
          <Button
            variant="contained"
            disabled={
              !validatePhone ||
              !isValid ||
              (Boolean(watch('editContract')) && !validateContract)
            }
            sx={{
              marginLeft: done ? 0 : 2,
              width: 110,
            }}
            onClick={done ? onAccept : handleSubmit(onConfirmValidate)}
          >
            {SubmitButtonContent()}
          </Button>
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default RevalidateUserForm;
