import * as yup from 'yup';

export const identificationSchema = yup.object({
  identification: yup
    .string()
    .typeError('Digite una identificación válida')
    .matches(/^\d+$/, 'Solo números')
    .test('len', 'Digite una identificación válida', (value) => {
      const strValue = value ? value.toString() : '';
      return strValue.length >= 6 && strValue.length <= 10;
    })
    .required('Es requerido'),
});
