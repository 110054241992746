import * as yup from 'yup';
import { ContractRelationshipEnum } from 'contactabilidad-common';
import {
  VERBAL_COMMUNICATION_CHANNEL_LIST,
  WRITTEN_COMMUNICATION_CHANNEL_LIST,
} from '../constants';

const contractRelationshipValues = [
  ContractRelationshipEnum.Owner,
  ContractRelationshipEnum.Tenant,
  ContractRelationshipEnum.Other,
];
export const revalidateOptionalSchema = yup.object({
  editContract: yup.boolean().required(),
  contract: yup.string().when('editContract', {
    is: true,
    then: (schema) =>
      schema
        .typeError('Digite un contrato válido')
        .matches(/^\d+$/, 'Solo números')
        .test('len', 'Digite un contrato válido', (value) => {
          const strValue = value ? value.toString() : '';
          return strValue.length >= 2 && strValue.length <= 10;
        }),
  }),
  contractRelationship: yup
    .mixed<ContractRelationshipEnum>()
    .when('editContract', {
      is: true,
      then: (schema) =>
        schema
          .typeError('Elija una opción')
          .oneOf(contractRelationshipValues)
          .required('Es requerido'),
    }),
  writtenCommunicationChannels: yup
    .array(
      yup.object({
        channel: yup
          .string()
          .oneOf(WRITTEN_COMMUNICATION_CHANNEL_LIST)
          .required('Es requerido'),
        included: yup.boolean().required(),
      })
    )
    .typeError('Seleccione un medio de contacto.')
    .min(1, 'Es requerido por lo menos un medio de contacto.')
    .required('Seleccione un medio de contacto válido.'),
  verbalCommunicationChannels: yup
    .array(
      yup.object({
        channel: yup
          .string()
          .oneOf(VERBAL_COMMUNICATION_CHANNEL_LIST)
          .required('Es requerido'),
        included: yup.boolean().required(),
      })
    )
    .typeError('Seleccione un medio de contacto.')
    .min(1, 'Es requerido por lo menos un medio de contacto.')
    .required('Seleccione un medio de contacto válido.'),
});
