import { Dispatch, SetStateAction } from 'react';
import Grid from '@mui/material/Grid';
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormClearErrors,
  Controller,
  FieldValues,
  Path,
} from 'react-hook-form';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { validateUserContract } from '../http/api/users';
import { ContractRelationshipEnum } from 'contactabilidad-common';
import ControlledTextInput from 'ui/components/inputs/controlled-text-input';
import ControlledSelectInput from 'ui/components/inputs/controlled-select-input';
import theme from '../styles/theme';
import { useCallback, useEffect, useState } from 'react';

type OptionalInterface<T> = {
  [key in keyof T]?: boolean | boolean[] | undefined;
};

interface IValidateUserContractFormProps<T extends FieldValues> {
  action: 'create' | 'update' | 'update-optional' | null;
  disabledContactValues?: boolean;
  setValue: UseFormSetValue<T>;
  clearErrors: UseFormClearErrors<T>;
  editContractEnabled: boolean;
  contract?: string;
  contractAddress: string;
  setContractAddress: Dispatch<SetStateAction<string>>;
  validateContract: boolean;
  setValidateContract: Dispatch<SetStateAction<boolean>>;
  control: Control<T>;
  errors: FieldErrors<T>;
  dirtyFields: Partial<Readonly<OptionalInterface<T>>>;
  defaultValues: Partial<T>;
  done?: boolean;
}

const ValidateUserContractForm = <T extends FieldValues>(
  props: IValidateUserContractFormProps<T>
): JSX.Element => {
  const {
    action,
    disabledContactValues,
    setValue,
    clearErrors,
    editContractEnabled,
    contract,
    contractAddress,
    setContractAddress,
    validateContract,
    setValidateContract,
    control,
    errors,
    dirtyFields,
    defaultValues,
    done,
  } = props;

  const [widthWindow, setWidthWindow] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidthWindow(window.innerWidth);
  };

  const validateOptions = [
    { label: 'Propietario', value: ContractRelationshipEnum.Owner },
    { label: 'Arrendatario', value: ContractRelationshipEnum.Tenant },
    { label: 'Otros', value: ContractRelationshipEnum.Other },
  ];

  const validateContractMutation = useMutation({
    mutationFn: validateUserContract,
  });

  const onValidateContract = useCallback(async () => {
    if (contract && !errors.contract) {
      const contractId = parseInt(contract, 10);
      const response = await validateContractMutation.mutateAsync(contractId);

      if (response.contract !== null) {
        setValidateContract(true);
        setContractAddress(
          `${response.contract.address}. ${response.contract.city}, ${response.contract.departemnt}`
        );
        toast.success('¡Contrato validado!');
      } else {
        toast.error('¡Contrato no encontrado!');
      }
    }
  }, [
    contract,
    errors.contract,
    setContractAddress,
    setValidateContract,
    validateContractMutation,
  ]);

  const handleOnEnterKeyPressContract = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      await onValidateContract();
    }
  };

  const switchLabelContent = () => {
    return (
      (action === 'create' && 'Tiene contrato') ||
      (action === 'update-optional' && 'Adicionar') ||
      (action === 'update' && 'Editar')
    );
  };

  const validateContractButtonContent = () => {
    if (validateContractMutation.isPending) {
      return <CircularProgress size={25} color="inherit" />;
    }
    return 'Validar';
  };

  const disableValidateContractButton = () => {
    return (
      (action === 'create' && disabledContactValues) ||
      !contract ||
      validateContract ||
      Boolean(errors.contract) ||
      !editContractEnabled ||
      done
    );
  };

  return (
    <Grid
      container
      direction="row"
      rowSpacing={2}
      columnSpacing={4}
      justifyContent={action === 'create' ? 'center' : 'flex-start'}
      alignItems="flex-start"
      marginTop={action === 'create' ? theme.spacing(1) : 0}
    >
      <Grid item xs={12}>
        <Box
          display="flex"
          marginLeft={action === 'create' ? 'auto' : '0'}
          marginRight={action === 'create' ? 'auto' : '0'}
          justifyContent={'flex-start'}
          sx={{
            maxWidth:
              action === 'create' && widthWindow <= 776 ? '300px' : '630px',
          }}
        >
          <FormControlLabel
            sx={{
              color: theme.palette.text.primary,
            }}
            value={true}
            control={
              <Controller
                name={'editContract' as Path<T>}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue('contract' as Path<T>, defaultValues.contract!);
                      setValue(
                        'contractRelationship' as Path<T>,
                        defaultValues.contractRelationship!
                      );
                      setContractAddress('');
                      setValidateContract(false);
                      clearErrors([
                        'contract',
                        'contractRelationship',
                      ] as Path<T>[]);
                    }}
                    inputProps={{
                      'aria-label': 'controlled',
                      disabled: disabledContactValues || done,
                    }}
                  />
                )}
              />
            }
            label={switchLabelContent()}
            labelPlacement="start"
          />
        </Box>
      </Grid>
      <Grid item>
        <Box display="flex">
          <ControlledTextInput
            label="N° contrato"
            name="contract"
            control={control}
            type="text"
            inputsProps={{
              sx: {
                width: action === 'create' ? '192px' : '152px',
              },
              disabled: !editContractEnabled || validateContract,
              maxLength: 10,
              onKeyDown: handleOnEnterKeyPressContract,
            }}
            errors={errors.contract}
            isDirty={Boolean(dirtyFields?.contract)}
          />
          <Button
            variant="outlined"
            disabled={disableValidateContractButton()}
            onClick={onValidateContract}
            sx={{
              height: 55,
              width: 100,
              marginLeft: theme.spacing(1),
              fontSize: 'small',
              textTransform: 'none',
              padding: theme.spacing(1.5),
              fontWeight: '600',
            }}
          >
            {validateContractButtonContent()}
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <ControlledSelectInput
          label="Relación con predio"
          name="contractRelationship"
          options={validateOptions}
          control={control}
          inputsProps={{
            sx: {
              width: action === 'create' ? '300px' : '260px',
            },
            disabled: !editContractEnabled || done,
          }}
          errors={errors.contractRelationship}
          isDirty={Boolean(dirtyFields?.contractRelationship)}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          marginLeft={action === 'create' ? 'auto' : '0'}
          marginRight={action === 'create' ? 'auto' : '0'}
          justifyContent={'flex-start'}
          sx={{
            maxWidth:
              action === 'create' && widthWindow <= 776 ? '300px' : '630px',
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              marginTop: theme.spacing(1),
              marginLeft: theme.spacing(1),
              fontSize: '0.9em',
              fontWeight: '600',
            }}
          >
            {contractAddress}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ValidateUserContractForm;
