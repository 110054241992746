import { CommunicationChannelEnum } from 'contactabilidad-common';

export const WRITTEN_COMMUNICATION_CHANNEL_LIST = [
  CommunicationChannelEnum.Letter,
  CommunicationChannelEnum.Email,
  CommunicationChannelEnum.SMS,
  CommunicationChannelEnum.WhatsApp,
];

export const VERBAL_COMMUNICATION_CHANNEL_LIST = [
  CommunicationChannelEnum.PhoneCall,
  CommunicationChannelEnum.TtsBot,
  CommunicationChannelEnum.OnSiteVisit,
];

export const COMMUNICATION_CHANNEL_LIST = [
  ...WRITTEN_COMMUNICATION_CHANNEL_LIST,
  ...VERBAL_COMMUNICATION_CHANNEL_LIST,
];
