import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: { // primary interface elements
      main: '#1050b3',
    },
    secondary: { // secondary interface element
      main: '#159cce',
    },
    text: {
      primary: '#0F4459',
      secondary: '#A5A1A1',
      disabled: '#8B8B8B',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#1050b3', // Color de fondo del botón
          color: '#fff', // Color del texto del botón
          '&:hover': {
            backgroundColor: '#159CCE', // Color de fondo en estado hover
            color: '#fff', // Color del texto en estado hover
          },
          '&.Mui-disabled': {
            backgroundColor: '#a9c1e8', // Color de fondo cuando el botón está desactivado
            color: '#fff', // Color del texto cuando el botón está desactivado
          },
        },
        outlined: {
          backgroundColor: '#fff',
          color: '#1050b3',
          border: 'solid #1050b3 1px',
          '&:hover': {
            backgroundColor: '#159CCE', // Color de fondo en estado hover
            color: '#fff', // Color del texto en estado hover
          },
          '&.Mui-disabled': {
            color: '#8B8B8B',
            backgroundColor: '#F3F2F2',
            border: 'solid #8B8B8B 1px',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          border: 'solid #CFCFD0 0,9px',
        },
      },
    },
    MuiInputBase: { // Estilos para los campos de entrada
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          '&.Mui-disabled': {
            backgroundColor: '#F3F2F2', // Color de fondo cuando el campo está desactivado
          },
        },
      },
    },
  },
});

export default theme;
